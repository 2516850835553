
import axios from 'axios';
export default {
    
    async createOffDayRequests(params)  {
        return await axios.post(`off_day_requests/create` , params)
    },
    async createOffDayRequestsList(params)  {
        return await axios.post(`off_day_requests/create/list` , params)
    },
    async updateOffDayRequestsColumn(column , value , data)  {
        return await axios.put(`off_day_requests/update_list?${column}=${value}` , data)
    },
    async deleteOffDayRequestsList(list)  {
        return await axios.delete(`off_day_requests/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportRequests(params)  {
        return await axios.post(`off_day_requests/report_request`, params)
    }
    ,
    async reportOffDayRequests(column , value)  {
        return await axios.get(`off_day_requests/report?${column}=${value}`)
    },
    async getAllOffDayRequests()  {
        return await axios.get(`off_day_requests/all`)
    },
    async getOneOffDayRequests(off_day_request_id)  {
        return await axios.get(`off_day_requests/all/${off_day_request_id}`)
    },
    async getOffDayRequestsByColumn(column , value)  {
        return await axios.get(`off_day_requests/filter?column=${column}&value=${value}`)
    },
    async deleteOffDayRequests(off_day_request_id)  {
        return await axios.delete(`off_day_requests/delete/${off_day_request_id}`)
    },
    async updateOffDayRequests(off_day_request_id , params)  {
        return await axios.put(`off_day_requests/update/${off_day_request_id}` , params)
    }
}

<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">
			<!-- {{employee}} -->
			<h2>
				{{$store.getters.language.data.employees.employee_name}} : {{user.employee_name}}

			</h2>
			<br>
			<h4>Off days per year : {{employee.employee.employee_off_days_year}}</h4>

			<h4>Used Off days Amount : {{days.off_day_amount}}</h4>

			<h4>Off days remaining : {{employee.employee.employee_off_days_year-days.off_day_amount}}</h4>

			<h4>Iligal Days : {{days.iligal_days_amount||0}} </h4>
			<form @submit.prevent="addOffDayRequests" autocomplete="off">
				<v-layout row wrap>
					<v-flex xs12 lg12 xl12 md12 sm12 class="mt-3 mb-7">

						<!-- {{user}} -->

					</v-flex>
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="off_day_requests.off_day_request_date" type="date" :label="$store.getters.language.data.off_day_requests.off_day_request_date" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-text-field v-model="off_day_requests.off_day_request_amount" type="number" :label="$store.getters.language.data.off_day_requests.off_day_request_amount" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>

					<!-- 	<v-flex xs12 lg4 xl4 md6 sm6>
                        <v-text-field v-model="off_day_requests.off_day_request_insert_date" type="datetime-local" :label="$store.getters.language.data.off_day_requests.off_day_request_insert_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex> -->

					<!-- <v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['pending','approved','declined']" v-model="off_day_requests.off_day_request_status" dense filled outlined item-text="off_day_request_status" item-value="off_day_request_status" :return-object="false" :label="$store.getters.language.data.off_day_requests.off_day_request_status">
						</v-select>
					</v-flex> -->

					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="['off_day','without_salary']" v-model="off_day_requests.off_day_request_type" dense filled outlined item-text="off_day_request_type" item-value="off_day_request_type" :return-object="false" :label="$store.getters.language.data.off_day_requests.off_day_request_type">
						</v-select>
					</v-flex>
					<!-- 
					<v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="users" v-model="off_day_requests.user_id" dense filled outlined item-text="user_id" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_id">
						</v-select>
					</v-flex> -->

					<!-- <v-flex xs12 lg4 xl4 md6 sm6>
						<v-select class="mx-1" clearable :items="employees" v-model="off_day_requests.employee_id" dense filled outlined item-text="employee_id" item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
						</v-select>
					</v-flex> -->
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-textarea v-model="off_day_requests.off_day_request_note" type="textarea" :label="$store.getters.language.data.off_day_requests.off_day_request_note" dense class="mx-1" filled outlined>
						</v-textarea>
					</v-flex>
					<v-flex xs12 lg12 xl12 md12 sm12>
						<v-btn color="primary" :loading="loading_btn" type="submit" width="100%">{{$store.getters.language.data.off_day_requests.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-divider class="mt-8"></v-divider>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="off_day_request_id">
								<template v-slot:[`item.off_day_request_id`]="{ item }">
									<div v-if="item.off_day_request_status == 'pending'">
										<!-- <v-btn icon :to="'/off_day_requests-list/'+item.off_day_request_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn> -->
										<v-btn color="error" icon class="mx-1" @click="selectOffDayRequests(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.off_day_request_date`]="{ item }">
									<!-- show only date -->
									{{new Date(item.off_day_request_date).toISOString().slice(0, 10).replace('T', ' ')}}
								</template>
								<template v-slot:[`item.off_day_request_insert_date`]="{ item }">
									{{new Date(item.off_day_request_insert_date).toISOString().slice(0, 19).replace('T', ' ')}}
								</template>
								<template v-slot:[`item.off_day_request_final_date`]="{ item }">
									<div v-if="item.off_day_request_final_date != null">
										{{new Date(item.off_day_request_final_date).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.off_day_request_status`]="{ item }">
									<div>
										<v-chip color="primary" v-if="item.off_day_request_status == 'pending'">
											{{item.off_day_request_status}}
										</v-chip>
										<v-chip color="success" v-if="item.off_day_request_status == 'approved'">
											{{item.off_day_request_status}}
										</v-chip>
										<v-chip color="green" v-if="item.off_day_request_status == 'viewing'">
											Approved By your Supervisour
										</v-chip>
										<div v-if="item.off_day_request_status == 'viewing'">
											<!-- {{item.off_day_request_status}} 
                                            <br> -->
											Still {{item.off_day_request_status}} by HR

										</div>
										<v-chip color="error" v-if="item.off_day_request_status == 'declined'">
											{{item.off_day_request_status}}
										</v-chip>
									</div>
								</template>
							</v-data-table>
						</v-card-text>
						<!-- 
						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteOffDayRequestsList">{{$store.getters.language.data.off_day_requests.delete_list_btn}}</v-btn>
						</v-card-actions> -->

					</v-card>
				</v-flex>
			</v-layout>
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.off_day_requests.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.off_day_requests.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteOffDayRequests(selected_off_day_requests.off_day_request_id)">
						{{$store.getters.language.data.off_day_requests.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/off_day_requests.request.js'
	import reportrequests from './../../requests/employees.request.js'
	export default {
		data() {
			return {
				off_day_requests: {},
				search: '',
				loading: true,
				employee: {},
				days: {},
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_off_day_requests: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_date',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_amount,
						align: 'start',
						sortable: true,
						value: 'off_day_request_amount',
					},

					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_note,
						align: 'start',
						sortable: true,
						value: 'off_day_request_note',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_status,
						align: 'start',
						sortable: true,
						value: 'off_day_request_status',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_type,
						align: 'start',
						sortable: true,
						value: 'off_day_request_type',
					},
					// {
					// 	text: this.$store.getters.language.data.users.user_id,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'user_id',
					// },
					// {
					// 	text: this.$store.getters.language.data.employees.employee_id,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'employee_id',
					// }
					// ,
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_insert_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_insert_date',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_final_note,
						align: 'start',
						sortable: true,
						value: 'off_day_request_final_note',
					},
					{
						text: this.$store.getters.language.data.off_day_requests.off_day_request_final_date,
						align: 'start',
						sortable: true,
						value: 'off_day_request_final_date',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'off_day_request_id',
					}
				],
			}
		},
		computed: {
			// users() {
			// 	return this.$store.getters.users_list
			// },
			// employees() {
			// 	return this.$store.getters.employees_list
			// },
			user() {
				return this.$store.getters.user
			}
		},
		mounted() {
			this.off_day_requests.employee_id = this.user.employee_id
			this.off_day_requests.user_id = this.user.user_id
			this.off_day_requests.off_day_request_status = 'pending'
			this.readOffDayRequests();
			this.readEmployeeDetails();
		},
		methods: {
			readEmployeeDetails() {
				reportrequests.getEmployeeDetails(this.user.employee_id).then(r => {
					if (r.status == 200) {
						this.employee = r.data
						this.days.off_day_amount = r.data.off_days_amount[0].off_day_amount
						this.days.iligal_days_amount = r.data.iligal_days_amount[0].iligal_days

					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee Details',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Employee Details',
							color: 'error'
						}
					})
			},
			addOffDayRequests() {
				if (this.off_day_requests.off_day_request_amount < 1) {
					this.snackbar = {
						value: true,
						text: 'Off Day Request Amount can not be negative',
						color: 'error'
					}
					return
				}
				if (this.off_day_requests.off_day_request_type == 'off_day' && this.off_day_requests.off_day_request_amount > this.employee.employee.employee_off_days_year - this.days.off_day_amount) {
					this.snackbar = {
						value: true,
						text: 'Off Day Request Cant be more than remaining off days',
						color: 'error'
					}
					return
				}
				if (this.off_day_requests.off_day_request_type == '' || this.off_day_requests.off_day_request_type == null) {
					this.snackbar = {
						value: true,
						text: 'Off Day Request Type can not be empty',
						color: 'error'
					}
					return
				}
				this.loading_btn = true
				requests.createOffDayRequests(this.off_day_requests).then(r => {
					if (r.status == 200) {
						this.off_day_requests = {
							off_day_request_status: 'pending',
							employee_id: this.user.employee_id,
							user_id: this.user.user_id
						}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'OffDayRequests Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add OffDayRequests',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteOffDayRequests(off_day_request_id) {
				requests.deleteOffDayRequests(off_day_request_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.off_day_request_id != off_day_request_id
						})
						this.snackbar = {
							value: true,
							text: 'OffDayRequests Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteOffDayRequestsList() {
				let ids = this.selected_rows.map(m => m.off_day_request_id)
				requests.deleteOffDayRequestsList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.off_day_request_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' OffDayRequests Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readOffDayRequests() {
				this.loading = true
				requests.getOffDayRequestsByColumn('employee_id', this.user.employee_id).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read OffDayRequests',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectOffDayRequests(i) {
				this.selected_off_day_requests = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    